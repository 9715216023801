// import { isActive:false, lazy } from "react";

import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DashboardIcon from "@mui/icons-material/Dashboard";
import InventoryIcon from "@mui/icons-material/Inventory";
export interface INavigation {
  title: string;
  isActive?: boolean;
  href: string;
  icon?: JSX.Element;
  path: string;
  key: string;
  role?: string[];
  parentKey?: string;
  hide?: boolean;
  children?: INavigation[];
  type?: string;
  menu?: any[];
  isOpen?: boolean;
  showIcon?: boolean;
}

export const navigation: INavigation[] = [
  //masteradmin access only (organizations work)
  {
    isActive: false,
    title: "Dashboard",
    href: "/",
    key: "/",
    icon: <DashboardIcon />,
    path: "./pages/dashboard/home_page",
    type: "masteradmin",
    role: ["masteradmin"],
    menu: [""],
  },
  {
    isActive: false,
    title: "Organizations",
    href: "/organizations",
    key: "organization",
    icon: <CreateNewFolderIcon />,
    path: "./pages/dashboard/modules/organizations",
    type: "masteradmin",
    menu: ["masteradmin"],
    role: ["masteradmin"],
  },
  {
    isActive: false,
    href: "/add-organization",
    key: "/add-organization",
    title: "Organizations",
    hide: true,
    menu: ["masteradmin"],

    path: "./pages/dashboard/modules/organizations/add_organizations",
  },

  {
    isActive: false,
    href: "/update-organization",
    title: "Organizations",
    hide: true,
    key: "/oooo",
    menu: ["masteradmin"],
    path: "./pages/dashboard/modules/organizations/update_organizations",
  },

  //admin access only (employees , master_data)
  // {
  //   isActive: false,
  //   title: "Employees",
  //   href: "/employees",
  //   icon: <EngineeringIcon />,
  //   role: ["admin"],
  //   key: "employees",
  //   type: "admin",
  //   menu: ["employees"],
  //   path: "./pages/dashboard/modules/employees",
  // },
  {
    isActive: false,
    title: "Employees",
    href: "/add-new-employee",

    hide: true,
    role: ["admin"],
    key: "addnewemployee",
    menu: ["employees"],
    path: "./pages/dashboard/modules/employees/add_employees_form",
  },
  {
    isActive: false,
    href: "/update-employee-details",
    key: "/update-employee-details",
    title: "Employees",
    menu: ["employees"],
    hide: true,
    path: "./pages/dashboard/modules/employees/update_employees_form",
  },
  //master_data
  // {
  //   isActive: false,
  //   title: "Master Data",
  //   href: "/masterdata/leave-types",
  //   icon: <DatasetIcon />,

  //   role: ["admin"],
  //   key: "master_data",
  //   menu: ["employees"],
  //   isOpen: false,
  //   showIcon: true,
  //   path: "",
  //   children: [
  //     {
  //       isActive: false,
  //       title: "Leave Types",
  //       href: "/masterdata/leave-types",

  //       role: ["admin"],
  //       key: "1",
  //       menu: ["employees"],
  //       path: "./pages/dashboard/modules/master_data/leave_types",
  //     },
  //     {
  //       isActive: false,
  //       title: "Leave Reasons",
  //       href: "/masterdata/leave-reasons",

  //       role: ["admin"],
  //       key: "2",
  //       menu: ["employees"],
  //       path: "./pages/dashboard/modules/master_data/leave_reasons",
  //     },

  //     {
  //       isActive: false,
  //       title: "Departments",
  //       href: "/masterdata/departments",

  //       role: ["admin"],
  //       key: "4",
  //       menu: ["employees"],
  //       path: "./pages/dashboard/modules/master_data/departments",
  //     },
  //     {
  //       isActive: false,
  //       title: "Designations",
  //       href: "/masterdata/designations",

  //       role: ["admin"],
  //       key: "3",
  //       menu: ["employees"],
  //       path: "./pages/dashboard/modules/master_data/designations",
  //     },
  //     {
  //       isActive: false,
  //       title: "Roles",
  //       href: "/roles",

  //       role: ["admin"],
  //       key: "roles",
  //       menu: ["employees"],
  //       path: "./pages/dashboard/modules/master_data/roles",
  //     },
  //     {
  //       isActive: false,
  //       href: "/masterdata/update-designation",
  //       key: "/masterdata/update-designation",
  //       title: "Designations",
  //       hide: true,
  //       path: "./pages/dashboard/modules/master_data/designations/add_update_designations",
  //       menu: ["employees"],
  //     },
  //     {
  //       isActive: false,
  //       href: "/masterdata/add-designation",
  //       key: "/masterdata/add-designation",
  //       title: "Designations",
  //       hide: true,
  //       path: "./pages/dashboard/modules/master_data/designations/add_update_designations",
  //       menu: ["employees"],
  //     },
  //     {
  //       isActive: false,
  //       href: "/masterdata/add-leave-types",
  //       key: "/masterdata/add-leave-types",
  //       title: "Leave Types",
  //       hide: true,
  //       menu: ["employees"],
  //       path: "./pages/dashboard/modules/master_data/leave_types/add_update_leave_types",
  //     },
  //     {
  //       isActive: false,
  //       href: "/masterdata/add-leave-reasons",
  //       key: "/masterdata/add-leave-reasons",
  //       title: "Leave Reasons",
  //       hide: true,
  //       menu: ["employees"],
  //       path: "./pages/dashboard/modules/master_data/leave_reasons/add_update_leave_reason",
  //     },
  //     {
  //       isActive: false,
  //       href: "/masterdata/add-departments",
  //       key: "/masterdata/add-departments",
  //       title: "Departments",
  //       hide: true,
  //       menu: ["employees"],
  //       path: "./pages/dashboard/modules/master_data/departments/add_update_departments",
  //     },
  //     {
  //       isActive: false,
  //       href: "/masterdata/update-leave-types",
  //       key: "/masterdata/update-leave-types",
  //       title: "Leave Types",
  //       hide: true,
  //       menu: ["employees"],
  //       path: "./pages/dashboard/modules/master_data/leave_types/add_update_leave_types",
  //     },
  //     {
  //       isActive: false,
  //       href: "/masterdata/update-leave-reason",
  //       key: "/masterdata/update-leave-reason",
  //       title: "Leave Reasons",
  //       hide: true,
  //       menu: ["employees"],
  //       path: "./pages/dashboard/modules/master_data/leave_reasons/add_update_leave_reason",
  //     },
  //     {
  //       isActive: false,
  //       href: "/masterdata/update-departments",
  //       key: "/masterdata/update-departments",
  //       title: "Departments",
  //       hide: true,
  //       menu: ["employees"],
  //       path: "./pages/dashboard/modules/master_data/departments/add_update_departments",
  //     },
  //     {
  //       isActive: false,
  //       href: "/master-data/leave-deduction-category",
  //       key: "/leave-deduction-category",
  //       title: "Leave Deduction",
  //       menu: ["employees"],
  //       path: "./pages/dashboard/modules/master_data/leave_deduction_category",
  //     },
  //     {
  //       isActive: false,
  //       href: "/master-data/add-leave-deduction-category",
  //       key: "/add-leave-deduction-category",
  //       title: "Leave Deduction",
  //       menu: ["employees"],
  //       hide: true,
  //       path: "./pages/dashboard/modules/master_data/leave_deduction_category/add_leave_deduction_category",
  //     },
  //     {
  //       isActive: false,
  //       href: "/master-data/update-leave-deduction-category",
  //       key: "/update-leave-deduction-category",
  //       title: "Leave Deduction",
  //       menu: ["employees"],
  //       hide: true,
  //       path: "./pages/dashboard/modules/master_data/leave_deduction_category/update_leave_deduction_category",
  //     },
  //   ],
  // },

  // //inventory
  {
    isActive: false,
    title: "Inventory",
    href: "/inventory-forms",
    icon: <InventoryIcon />,
    path: "",
    key: "inventory",

    menu: ["inventory"],
    children: [
      {
        isActive: false,
        title: "Categories",
        href: "/inventory/categories",
        role: ["admin"],
        key: "inventroy-categories",
        parentKey: "/inventory-forms",
        // hide: true,
        menu: ["inventory"],
        path: "./pages/dashboard/modules/inventory/inventory_categories",
      },
      {
        isActive: false,
        title: "Category Records",
        href: "/inventory/categories",
        role: ["admin"],
        key: "inventroy-categories-records",
        parentKey: "/inventory-forms",

        // hide: true,
        menu: ["inventory"],
        path: "./pages/dashboard/modules/inventory/inventory_categories",

        children: [
          {
            isActive: false,
            title: "Categories",
            href: "/inventory/categories",
            role: ["admin"],
            key: "inventroy-categories-child",
            parentKey: "/inventory-forms",

            // hide: true,
            menu: ["inventory"],
            path: "./pages/dashboard/modules/inventory/inventory_categories",
          },
        ],
      },
    ],
  },
  {
    isActive: false,
    title: "Inventory",
    href: "/create-inventory-form",

    hide: true,
    role: ["employee", "hr", "admin"],
    parentKey: "/inventory-forms",

    key: "create-inventory-form",
    menu: ["inventory"],
    path: "./pages/dashboard/modules/inventory/category_records/create_inventory_form",
  },

  {
    isActive: false,
    title: "Inventory",
    href: "/update-inventory-form/:key",
    hide: true,
    parentKey: "/inventory-forms",

    role: ["employee", "hr", "admin"],
    key: "/update-inventory-form/:key",
    path: "./pages/dashboard/modules/inventory/category_records/update_inventory_form",
    menu: ["inventory"],
  },
  {
    isActive: false,
    title: "Inventory",
    href: "/add-inventory-form-records/:key",
    parentKey: "/inventory-forms",

    hide: true,
    role: ["employee", "hr", "admin"],
    key: "submit-inventory",
    type: "employee,hr",
    menu: ["inventory"],
    path: "./pages/dashboard/modules/inventory/category_records/inventory_form_records/add_inventory_records",
  },
  {
    isActive: false,
    title: "Inventory",
    href: "/inventory-form-records/:id",
    hide: true,
    parentKey: "/inventory-forms",

    role: ["employee", "hr", "admin"],
    key: "inventory-form-details",
    menu: ["inventory"],
    path: "./pages/dashboard/modules/inventory/category_records/inventory_form_records",
  },
  {
    isActive: false,
    title: "Inventory",
    href: "/update-inventory-form/:name",
    parentKey: "/inventory-forms",

    menu: ["inventory"],

    hide: true,
    role: ["employee", "hr", "admin"],
    key: "updateinventoryform",
    type: "employee,hr",
    path: "./pages/dashboard/modules/inventory/create_inventory_form",
  },
  // //requested-leaves-data
  //   {
  //     isActive: false,
  //     title: "Requested Leaves",
  //     href: "/requested-leaves",
  //     icon: <PendingActionsIcon />,
  //     role: ["employee", "hr"],
  //     key: "idednventory",
  //     menu: ["leaves_requested"],
  //     path: "./pages/dashboard/modules/requested_leaves",
  //   },
  //   {
  //     isActive: false,
  //     title: "Requested Leaves",
  //     href: "/requested-leave-details",

  //     role: ["employee", "hr"],
  //     key: "applied-leave-details",
  //     menu: ["leaves_requested"],
  //     hide: true,
  //     path: "./pages/dashboard/modules/requested_leaves/requested_leaves_details",
  //   },
  //   // //employeeLeaves

  //   {
  //     isActive: false,
  //     title: "Leaves",
  //     href: "/leaves",
  //     icon: <FolderIcon />,

  //     role: ["Employee"],
  //     key: "leaves",
  //     menu: ["employee_leaves"],
  //     path: "./pages/dashboard/modules/leaves",
  //   },

  //   {
  //     isActive: false,
  //     href: "/add-leave",
  //     key: "/leavesdata/add-leave",
  //     title: "Leaves",
  //     menu: ["employee_leaves"],
  //     hide: true,
  //     path: "./pages/dashboard/modules/leaves/add_leaves",
  //   },
  //   {
  //     isActive: false,
  //     href: "/your-leave-details/:id",
  //     key: "/your-leave-details/",
  //     title: "Leaves",
  //     hide: true,
  //     path: "./pages/dashboard/modules/leaves/leave_details",
  //     menu: ["employee_leaves"],
  //   },

  //   //salary module
  //   {
  //     isActive: false,
  //     href: "/salary-rules",
  //     key: "/salary-rules",

  //     path: "./pages/dashboard/modules/salary_rules",
  //     title: "Salary Rules",
  //     menu: ["salary"],
  //     icon: <AttachMoneyIcon />,
  //   },
  //   {
  //     isActive: false,
  //     href: "/add-salary-rules",
  //     key: "/add-salary-rules",
  //     hide: true,
  //     path: "./pages/dashboard/modules/salary_rules/add_salary_rules",
  //     title: "Salary Rules",
  //     menu: ["salary"],
  //   },
  //   {
  //     isActive: false,
  //     href: "/update-salary-rules",
  //     key: "/update-salary-rules",
  //     hide: true,
  //     path: "./pages/dashboard/modules/salary_rules/update_salary_rules",
  //     title: "Salary Rules",
  //     menu: ["salary"],
  //   },

  //   //profile
  //   {
  //     isActive: false,
  //     href: "/user-profile",
  //     key: "/user-profile",

  //     path: "./pages/dashboard/profile",
  //     title: "",
  //     hide: true,
  //   },
  //   {
  //     isActive: false,
  //     href: "/update-your-profile",
  //     key: "/update-your-profile",

  //     path: "./pages/dashboard/profile/update_profile",
  //     title: "",
  //     hide: true,
  //   },
  //   {
  //     isActive: false,
  //     href: "/update-profile-password",
  //     key: "/update-profile-password",

  //     path: "./pages/dashboard/profile/update_profile_password",
  //     title: "",
  //     hide: true,
  //   },
];
