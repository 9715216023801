import { createContext, useState } from "react";
import { IInventoryAllFormsListData } from "../../interfaces/response/IInventoryAllFormsListResponse";
export interface IContextType {
  data?: IInventoryAllFormsListData[] | [];
  setData?: React.Dispatch<React.SetStateAction<IInventoryAllFormsListData[]>>;
  loader?: boolean; // Specify the type for the "loader" property
  setLoader?: React.SetStateAction<any>;
}
export const inventoryCategoriesContext = createContext<IContextType>({
  data: [],
});
export const InventoryContextProvider = ({ children }: any) => {
  const [data, setData] = useState<IInventoryAllFormsListData[]>([]);
  const [loader, setLoader] = useState<boolean>(false);
  return (
    <inventoryCategoriesContext.Provider
      value={{ data, setData, loader, setLoader }}
    >
      {children}
    </inventoryCategoriesContext.Provider>
  );
};
