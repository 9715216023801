export const allAreaCommonRoles: [
  {
    admin: string[];
    masteradmin: string[];
  }
] = [
  {
    admin: ["admin"],
    masteradmin: ["masteradmin"],
  },
];
