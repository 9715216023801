import { Card, TextField } from "@material-ui/core";
import LoadingButton from "@mui/lab/LoadingButton";
import { CircularProgress } from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { errorNotify, successNotify } from "../../../components/notifications";
import CompanyManagementSystem from "../../../services";
import ".././../../assets/styles/login.css";
import { resetPasswordValidationSchema } from "./reset_password_validations";
const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (location.state === null) navigate("/signin");
  }, []);
  return (
    <div style={{ marginTop: "9rem" }}>
      <div className="container">
        <Formik
          onSubmit={async (values) => {
            setLoading(true);
            let response = await CompanyManagementSystem.resetPassword({
              email: location.state,
              otp: values.otp ?? 0,
              new_password: values.password,
            });
            if (response.status_code === 200) {
              navigate("/signin");
              successNotify("Your password is reseted.");
            } else {
              errorNotify(response.errors[0].message);
            }
            setLoading(false);
          }}
          initialValues={{
            email: location.state,
            password: "",
            confirmPassword: "",
            otp: undefined,
          }}
          validationSchema={resetPasswordValidationSchema}
        >
          {({ errors, touched, setFieldValue, handleSubmit, values }) => (
            <Card className=" p-5 ">
              <div className="text-center">
                <h5 className="appheading">Reset Your Password</h5>
                <p>
                  Confirm your OTP and Your password must <br />
                  be at least 6 characters and should include <br />a
                  combination of numbers, letters and <br />
                  special characters (!$@%).
                </p>
              </div>
              <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                  <TextField
                    disabled
                    required
                    inputProps={{ style: { color: "black" } }}
                    name="email"
                    type="text"
                    defaultValue={values.email}
                    value={values.email}
                    fullWidth
                    id="standard-basic"
                    variant="outlined"
                  />
                </div>
                <div className="col-md-12"></div>
                <div className="col-md-3"></div>
                <div className="col-md-6 mt-4">
                  <TextField
                    required
                    name="otp"
                    type="text"
                    fullWidth
                    placeholder="Enter your OTP"
                    id="standard-basic"
                    variant="outlined"
                    onChange={(e) => setFieldValue("otp", e.target.value)}
                    error={touched.otp && Boolean(errors.otp)}
                    helperText={touched.otp && errors.otp}
                  />
                </div>
                <div className="col-md-12"></div>
                <div className="col-md-3 "></div>
                <div className="col-md-6 mt-4">
                  <TextField
                    required
                    name="password"
                    type="password"
                    fullWidth
                    placeholder="Enter your new password"
                    id="standard-basic"
                    variant="outlined"
                    onChange={(e) => setFieldValue("password", e.target.value)}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </div>
                <div className="col-md-12"></div>
                <div className="col-md-3 "></div>
                <div className="col-md-6 mt-4">
                  <TextField
                    required
                    type="password"
                    name="confirmPassword"
                    fullWidth
                    placeholder="Confirm your new password"
                    id="standard-basic"
                    variant="outlined"
                    onChange={(e) =>
                      setFieldValue("confirmPassword", e.target.value)
                    }
                    error={
                      touched.confirmPassword && Boolean(errors.confirmPassword)
                    }
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                  />
                </div>
              </div>
              <div className="centerButton mt-3">
                <LoadingButton      loadingIndicator={<CircularProgress size={20}  style={{
                        color:"white"
                      }} />}
                  loading={loading}
                  type="submit"
                  onClick={() => handleSubmit()}
                  variant="contained"
                  color="primary"
                  style={{ background: "#0d407d" }}
                >
                  Reset your password
                </LoadingButton>
              </div>
            </Card>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResetPassword;
