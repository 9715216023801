import ReactDOM from "react-dom";
import App from "./App";
import { AuthContextProvider } from "./helpers/context/auth.context";
import { InventoryContextProvider } from "./helpers/context/inventory_categories.context";
import "./index.css";

ReactDOM.render(
  <AuthContextProvider>
    <InventoryContextProvider>
      <App />
    </InventoryContextProvider>
  </AuthContextProvider>,
  document.getElementById("root")
);
