import { createContext, useState } from "react";
import { ILocalStorageUser } from "../../interfaces";
interface IData {
  loggedInUser?: ILocalStorageUser;
  setLoggedInUser: (values: any) => void;
}
export const AuthContext = createContext<IData>({
  setLoggedInUser: (values: any) => values,
});
export const AuthContextProvider = ({ children }: any) => {
  const [loggedInUser, setLoggedInUser] = useState<ILocalStorageUser>(
    localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user")!)
      : undefined
  );
  return (
    <>
      <AuthContext.Provider value={{ loggedInUser, setLoggedInUser }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};
