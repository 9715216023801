import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Button } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/styles/main.css";
import { AuthContext } from "../helpers/context/auth.context";
import { navigation } from "../routes/router";
const PageNotFound = () => {
  const navigate = useNavigate();
  const { loggedInUser } = useContext(AuthContext);
  const navigateHandler = () => {
    navigation.filter((X) => {
      if (loggedInUser?.menu?.includes(X.menu?.toString())) {
        navigate(`${X.href}`);
      } else {
        navigate("/");
      }
    });
  };
  return (
    <>
      <div className="container">
        <div className="mt-5">
          <div style={{ color: "blue", textAlign: "center" }}>
            <ErrorOutlineIcon style={{ fontSize: "20rem" }} />
            <h1>Page Not Found</h1>
          </div>
        </div>
        <div className="mt-5 itemcenter">
          <Button
            style={{ background: "#0d407d" }}
            onClick={() => navigateHandler()}
            variant="contained"
          >
            Go Back
          </Button>
        </div>
      </div>
    </>
  );
};

export default PageNotFound;
