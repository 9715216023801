import { Card } from "@material-ui/core";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { CircularProgress } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import signin from "../../../assets/images/crm/signinlogo.png";
import { errorNotify, successNotify } from "../../../components/notifications";
import { AuthContext } from "../../../helpers/context/auth.context";
import CompanyManagementServices from "../../../services";
import { allAreaCommonRoles } from "../../../utils/common";
const SignIn = () => {
  const [loading, setLoading] = useState<boolean>();
  const updatedgmail = JSON.parse(localStorage.getItem("newgmail")!);
  const navigate = useNavigate();
  const location = useLocation();
  const { setLoggedInUser } = useContext(AuthContext);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });
  useEffect(() => {
    if (location.pathname === "/signin" && localStorage.getItem("user"))
      navigate("/");
  }, []);
  useEffect(() => {});
  return (
    <>
      <div className="container divCenter ">
        <div>
          <Card style={{ height: "35rem" }}>
            <div style={{ padding: "4rem", marginTop: "4rem" }} className="row">
              <img className="col-md-6 col-xs-12 col-sm-12" src={signin} />
              <div className="col-md-6">
                <Formik
                  initialValues={{
                    email: updatedgmail ? updatedgmail.toString() : "",
                    password: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (values) => {
                    setLoading(true);
                    let response = await CompanyManagementServices.login(
                      values
                    );
                    if (response?.status_code === 200) {
                      let filterResponse = response.data.menus.filter(
                        (X: any) => {
                          if (["leaves"].includes(X.menu_key.toString())) {
                            X.menu_key = "leaves_requested";
                          }
                          return X.menu_key;
                        }
                      );
                      let obj = {
                        ...values,
                        password: undefined,
                        image: response.data?.image,
                        token: response.data.token,
                        name: response.data.name,
                        user_id: response.data.user_id,
                        menu:
                          response.data.roles.toString() ===
                          allAreaCommonRoles
                            .map((X) => X.masteradmin.toString())
                            .toString()
                            ? ["masteradmin"]
                            : response.data.roles.toString() ===
                              allAreaCommonRoles
                                .map((X) => X.admin.toString())
                                .toString()
                            ? [
                                "inventory",
                                "employees",
                                "leaves_requested",
                                "salary",
                              ]
                            : filterResponse.map((x: any) => x.menu_key),
                        roles: response.data.roles,
                      };
                      localStorage.setItem("user", JSON.stringify(obj));
                      setLoggedInUser(obj);
                      successNotify("Logged In Successfull");
                      navigate("/");
                      if (localStorage.getItem("newgmail"))
                        localStorage.removeItem("newgmail");
                    } else {
                      errorNotify(response.errors[0].message);
                    }
                    setLoading(false);
                  }}
                >
                  {({
                    values,
                    handleSubmit,
                    setFieldValue,
                    errors,
                    touched,
                  }: any) => (
                    <Form
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit();
                        }
                      }}
                    >
                      <div>
                        <div>
                          <div>
                            <h3 className="text-center appheading">
                              <b>Sign In</b>
                            </h3>
                            <div className="centerButton ">
                              <div className="mt-3">
                                <div className="row">
                                  <div className="col-md-12">
                                    <TextField
                                      className="fieldsbackground"
                                      autoFocus
                                      required
                                      variant="outlined"
                                      fullWidth
                                      id="email"
                                      name="email"
                                      label="Email"
                                      value={values.email}
                                      onChange={(e) =>
                                        setFieldValue("email", e.target.value)
                                      }
                                      error={
                                        touched.email && Boolean(errors.email)
                                      }
                                    />
                                    <span className="fielderror">
                                      {touched.email && errors.email}
                                    </span>
                                  </div>

                                  <div className="col-md-12 mt-4">
                                    <FormControl
                                      fullWidth
                                      className="col-md-12"
                                      variant="outlined"
                                    >
                                      <InputLabel htmlFor="outlined-adornment-password">
                                        Password
                                      </InputLabel>
                                      <OutlinedInput
                                        fullWidth
                                        name="password"
                                        onChange={(e) =>
                                          setFieldValue(
                                            "password",
                                            e.target.value
                                          )
                                        }
                                        error={
                                          touched.password &&
                                          Boolean(errors.password)
                                        }
                                        id="outlined-adornment-password"
                                        type={
                                          showPassword ? "text" : "password"
                                        }
                                        endAdornment={
                                          <InputAdornment position="end">
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                              onMouseDown={
                                                handleMouseDownPassword
                                              }
                                              edge="end"
                                            >
                                              {showPassword ? (
                                                <VisibilityOff />
                                              ) : (
                                                <Visibility />
                                              )}
                                            </IconButton>
                                          </InputAdornment>
                                        }
                                        label="Password"
                                      />
                                    </FormControl>
                                    <span className="fielderror">
                                      {touched.password && errors.password}
                                    </span>
                                  </div>

                                  <span
                                    onClick={() =>
                                      navigate("/forgot-your-password")
                                    }
                                    className="col-md-6 mt-3"
                                    style={{ color: "blue", cursor: "pointer" }}
                                  >
                                    Forgot Password?
                                  </span>
                                  <div className="centerButton">
                                    <LoadingButton
                                      onClick={() => handleSubmit()}
                                      loading={loading}
                                      loadingIndicator={
                                        <CircularProgress
                                          style={{
                                            color: "white",
                                          }}
                                          size={20}
                                        />
                                      }
                                      className="mt-12 col-md-2 mt-3 "
                                      color="primary"
                                      variant="contained"
                                      onSubmit={() => handleSubmit()}
                                      type="submit"
                                      style={{ background: "#0d407d" }}
                                    >
                                      Submit
                                    </LoadingButton>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default SignIn;
