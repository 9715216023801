import * as yup from "yup";

export const resetPasswordValidationSchema = yup.object({
  password: yup
    .string()
    .required("Enter your password")
    .matches(
      /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
      "Passowrd should be one special character, no space, and it must be 6-16 characters long"
    ),
  confirmPassword: yup
    .string()
    .required("Confirm your password")
    .oneOf([yup.ref("password"), null], "Passwords doesn't match"),
  otp: yup.string().required("Enter your OTP"),
});
