import { Button } from "@material-ui/core";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import errorImage from "../assets/images/unknown_error/error.png";
import "../assets/styles/unknownerror.css";
import Loader from "../components/loader/loader";
const UnknownErrorPage = () => {
  const loggedInUser = JSON.parse(localStorage.getItem("user")!);
  const navigate = useNavigate();
  const location = useLocation();
  const loginAgainHandler = () => {
    localStorage.clear();
    navigate("/signin");
  };
  useEffect(() => {
    if (!loggedInUser || (loggedInUser?.menu && loggedInUser?.menu?.length > 0))
      navigate(-1);
  }, [location.pathname]);
  return (
    <>
      {!loggedInUser ||
      (loggedInUser?.menu && loggedInUser?.menu?.length > 0) ? (
        <Loader />
      ) : (
        <div className="parent">
          <div className="centerItems mt-5">
            <img src={errorImage} height={400} />
          </div>
          <h2 className="textCenter updateiconstyling mt-3">
            Sorry,Unknown error occurs . Try to login again with another account
            .
          </h2>
          <div className="centerItems mt-4">
            <Button
              color="primary"
              variant="contained"
              style={{ background: "#0d407d" }}
              onClick={() => loginAgainHandler()}
            >
              Login Again
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default UnknownErrorPage;
