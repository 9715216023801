import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { Collapse } from "@mui/material";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { alpha, styled } from "@mui/material/styles";
import { StatusCodes } from "http-status-codes";
import _, { debounce } from "lodash";

import * as React from "react";
import { Suspense, useContext, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import crm from "../assets/images/crm/crm logo.png";
import "../assets/styles/sidebar.css";
import Loader from "../components/loader/loader";
import { inventoryCategoriesContext } from "../helpers/context/inventory_categories.context";
import { INavigation, navigation } from "../routes/router";
import services from "../services";

const drawerWidth = 290;
interface Props {
  window?: () => Window;
  mobileOpen: any;
  width: any;
  setMobileOpen: any;
}
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const Sidebar = (props: Props) => {
  const navigate = useNavigate();
  const { loader, setData, data } = useContext<any>(inventoryCategoriesContext);
  const loggedInUser = localStorage.getItem("user");
  const { window } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const location = useLocation();
  const [isSubchildFounded, setIsSubchildFounded] =
    React.useState<boolean>(true);
  const [menusData, setMenusData] = React.useState(navigation);
  const handlingSidebar = (
    data: INavigation[],
    naviKey: string,
    type?: string
  ) => {
    let tempData: any = data.map((x) => {
      if (naviKey === x.key || x.href === naviKey)
        x = { ...x, isActive: !x.isActive, isOpen: !x.isOpen };
      else if (type !== "child") x = { ...x, isActive: false };
      if (x.children && x.children.length > 0)
        x.children = handlingSidebar(x.children, naviKey, type);
      return x;
    });
    return tempData;
  };
  const handleClickList = (naviKey: string, type?: string) => {
    setMenusData((prev) => {
      prev = handlingSidebar(prev, naviKey, type);
      return [...prev];
    });
  };
  useEffect(() => {
    let isActiveRoute = menusData.find((child) =>
      child.children?.find((x) => x.href === location.pathname)
    );
    setMenusData((prev) => {
      prev = handlingSidebar(prev, isActiveRoute?.href ?? "");
      return [...prev];
    });
  }, []);

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));
  const getInventoryCategories = async () => {
    setLoading(true);
    let response = await services.getAllInventoryFormsLists();
    if (response.status_code === StatusCodes.OK) {
      setData(response.data);
      if (response.data.length > 0) setIsSubchildFounded(true);
      else setIsSubchildFounded(false);
    }
    setLoading(false);
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;
  useEffect(() => {
    if (data.length > 0)
      setMenusData((prev) => {
        return prev.map((x) => {
          return {
            ...x,
            children: x.children?.map((child) => {
              if (
                child.children &&
                child.children.length > 0 &&
                child.key === "inventroy-categories-records"
              ) {
                child = {
                  ...child,
                  children: data.map((x: any, i: number) => {
                    return {
                      title: x.key,
                      href: `/inventory-form-records/${x._id}`,
                      role: ["admin"],
                      key: x.key,
                      // hide: true,
                      menu: ["inventory"],
                      path: "./pages/dashboard/modules/inventory/inventory_categories",
                    };
                  }),
                };
              }
              return child;
            }),
          };
        });
      });
  }, [data]);

  const handleSearchCategory = React.useCallback(
    debounce(async (value: string) => {
      setLoading(true);
      let response = await services.searchInventoryCategory(value);
      if (response.status_code === StatusCodes.OK) {
        setIsSubchildFounded(true);
        setData(response.data);
        if (value && response.data.length === 0) setIsSubchildFounded(false);
      }

      setLoading(false);
    }, 1000),
    []
  );
  useEffect(() => {
    getInventoryCategories();
  }, []);
  const drawer = (
    <div>
      <Divider />
      <List>
        {_.sortBy(menusData, ["title"]).map((values, index) => {
          if (!values.children) {
            if (
              values.menu &&
              loggedInUser?.includes(values.menu?.toString()) &&
              !values.hide
            ) {
              return (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    selected={values.isActive}
                    onClick={() => {
                      navigate(values.href);
                      handleClickList(values.key, "parent");
                    }}
                  >
                    <ListItemIcon>{values.icon}</ListItemIcon>

                    <ListItemText
                      primary={
                        <span
                          style={{
                            color:
                              values.isActive ||
                              location.pathname === values.href
                                ? "#0d407d"
                                : "",
                            fontWeight:
                              values.isActive ||
                              location.pathname === values.href
                                ? "bolder"
                                : "",
                          }}
                        >
                          {values.title}
                        </span>
                      }
                    />
                  </ListItemButton>
                </ListItem>
              );
            }
          } else if (values.children && values.children.length > 0) {
            return (
              <div key={index}>
                {values.menu &&
                  loggedInUser?.includes(values.menu?.toString()) &&
                  !values.hide && (
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                      }}
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                    >
                      <ListItemButton
                        selected={values.isActive}
                        onClick={() => handleClickList(values.key, "child")}
                      >
                        <ListItemIcon>{values.icon}</ListItemIcon>
                        <ListItemText primary={values.title} />
                        {values.isOpen ? <ExpandLess /> : <ExpandMore />}
                      </ListItemButton>
                      {values?.children?.map((child, index) => {
                        if (!child.children)
                          return (
                            !child.hide && (
                              <div key={index}>
                                <Collapse
                                  in={values.isOpen}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List component="div" disablePadding>
                                    <ListItemButton
                                      selected={child.isActive}
                                      onClick={() => {
                                        navigate(child.href);
                                        handleClickList(child.key, "parent");
                                      }}
                                      sx={{ pl: 4 }}
                                    >
                                      {child.title && (
                                        <ListItemIcon>
                                          <ul>
                                            <li></li>
                                          </ul>
                                        </ListItemIcon>
                                      )}

                                      <ListItemText
                                        primary={
                                          <span
                                            style={{
                                              color:
                                                child.isActive ||
                                                location.pathname === child.href
                                                  ? "#0d407d"
                                                  : "",
                                              fontWeight:
                                                child.isActive ||
                                                location.pathname === child.href
                                                  ? "bolder"
                                                  : "",
                                            }}
                                          >
                                            {child.title}
                                          </span>
                                        }
                                      />
                                    </ListItemButton>
                                  </List>
                                </Collapse>
                              </div>
                            )
                          );
                        else
                          return values.children?.map((child) => {
                            if (child.children)
                              return (
                                <>
                                  <Collapse
                                    in={values.isOpen}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <List
                                      sx={{
                                        width: "100%",
                                        maxWidth: 360,
                                        bgcolor: "background.paper",
                                      }}
                                      component="nav"
                                      aria-labelledby="nested-list-subheader"
                                    >
                                      <ListItemButton
                                        selected={child.isActive}
                                        onClick={() =>
                                          handleClickList(child.key, "child")
                                        }
                                      >
                                        <ListItemIcon>
                                          {child.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={child.title} />
                                        {child.isOpen ? (
                                          <ExpandLess />
                                        ) : (
                                          <ExpandMore />
                                        )}
                                      </ListItemButton>
                                      {child.isOpen && (
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Search>
                                            <SearchIconWrapper>
                                              <SearchIcon />
                                            </SearchIconWrapper>
                                            <StyledInputBase
                                              onChange={(e) => {
                                                handleSearchCategory(
                                                  e.target.value
                                                );
                                              }}
                                              placeholder="Search…"
                                              inputProps={{
                                                "aria-label": "search",
                                              }}
                                            />
                                          </Search>
                                        </div>
                                      )}
                                      {loading ? (
                                        <Loader />
                                      ) : isSubchildFounded ? (
                                        <div
                                          style={{
                                            maxHeight: "25rem",
                                            overflowY: "auto",
                                          }}
                                        >
                                          {child.children?.map((subchild) => {
                                            return (
                                              !subchild.hide && (
                                                <div key={index}>
                                                  <Collapse
                                                    in={child.isOpen}
                                                    timeout="auto"
                                                    unmountOnExit
                                                  >
                                                    <List
                                                      component="div"
                                                      disablePadding
                                                    >
                                                      <ListItemButton
                                                        selected={
                                                          subchild.isActive ||
                                                          location.pathname ===
                                                            subchild.href
                                                        }
                                                        onClick={() => {
                                                          navigate(
                                                            subchild.href
                                                          );
                                                          handleClickList(
                                                            subchild.key,
                                                            "parent"
                                                          );
                                                        }}
                                                        sx={{ pl: 4 }}
                                                      >
                                                        {subchild.title && (
                                                          <ListItemIcon>
                                                            <ul>
                                                              <li></li>
                                                            </ul>
                                                          </ListItemIcon>
                                                        )}

                                                        <ListItemText
                                                          primary={
                                                            <span
                                                              style={{
                                                                color:
                                                                  subchild.isActive ||
                                                                  location.pathname ===
                                                                    subchild.href
                                                                    ? "#0d407d"
                                                                    : "",
                                                                fontWeight:
                                                                  subchild.isActive ||
                                                                  location.pathname ===
                                                                    subchild.href
                                                                    ? "bolder"
                                                                    : "",
                                                              }}
                                                            >
                                                              {subchild.title}
                                                            </span>
                                                          }
                                                        />
                                                      </ListItemButton>
                                                    </List>
                                                  </Collapse>
                                                </div>
                                              )
                                            );
                                          })}
                                        </div>
                                      ) : (
                                        <p className="text-center mt-4">
                                          No Data Found ....
                                        </p>
                                      )}
                                    </List>
                                  </Collapse>
                                </>
                              );
                          });
                      })}
                    </List>
                  )}
              </div>
            );
          }
        })}
      </List>
    </div>
  );
  const handleDrawerToggle = () => {
    props.setMobileOpen(!props.mobileOpen);
  };
  useEffect(() => {
    props.width(drawerWidth);
  }, [drawerWidth]);

  return (
    <>
      {!data ? (
        <Loader />
      ) : (
        <>
          <Box
            component="nav"
            sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
            aria-label="mailbox folders"
          >
            <Drawer
              onClose={handleDrawerToggle}
              container={container}
              variant="temporary"
              open={props.mobileOpen}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { xs: "block", md: "none" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
            >
              <div className="p-2">
                <DrawerHeader>
                  <img
                    onClick={() => navigate("/")}
                    className="cursorstyling"
                    src={crm}
                    height={"60rem"}
                    width={"205rem"}
                    style={{ marginRight: "1.1rem" }}
                  />
                  <IconButton onClick={() => props.setMobileOpen(false)}>
                    <CloseIcon />
                  </IconButton>
                </DrawerHeader>
              </div>
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: "none", md: "block" },
                "& .MuiDrawer-paper": {
                  boxSizing: "border-box",
                  width: drawerWidth,
                },
              }}
              open
            >
              <div className="p-2">
                <DrawerHeader>
                  <img
                    onClick={() => navigate("/")}
                    className="mt-1 cursorstyling"
                    src={crm}
                    height={"60rem"}
                    width={"205rem"}
                    style={{ marginRight: "3rem" }}
                  />
                </DrawerHeader>
              </div>

              {drawer}
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { md: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <Toolbar />

            <Suspense fallback={<Loader />}>
              <div className="outlet">{loader ? <Loader /> : <Outlet />}</div>
            </Suspense>
          </Box>
        </>
      )}
    </>
  );
};
export default Sidebar;
