import { Form, Formik } from "formik";

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Card, CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import signin from "../../../assets/images/crm/signinlogo.png";
import { errorNotify, successNotify } from "../../../components/notifications";
import CompanyManagementServices from "../../../services";
import { forgotPasswordValidationSchema } from "./forgot_password_validations";

const ForgotPassword = () => {
  const [loading, setLoading] = useState<boolean>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/signin" && localStorage.getItem("user"))
      navigate("/");
  }, []);
  return (
    <>
      <div className="container divCenter ">
        <div>
          <Card style={{ height: "30rem" }}>
            <div style={{ margin: "4rem" }} className="row">
              <img className="col-md-6 col-xs-12 col-sm-12" src={signin} />
              <div className="col-md-6">
                {" "}
                <Formik
                  onSubmit={async (values) => {
                    setLoading(true);
                    let response =
                      await CompanyManagementServices.forgotPassword({
                        email: values.email,
                      });
                    if (response.status_code === 200) {
                      successNotify(response.data);
                      navigate("/reset-your-password", {
                        state: values.email,
                      });
                    } else {
                      errorNotify(response.errors[0].message);
                    }
                    setLoading(false);
                  }}
                  initialValues={{
                    email: "",
                  }}
                  validationSchema={forgotPasswordValidationSchema}
                >
                  {({ handleSubmit, setFieldValue, errors, touched }: any) => (
                    <Form
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSubmit();
                        }
                      }}
                    >
                      <div>
                        <div>
                          <div>
                            <div className="centerButton ">
                              <div className="row">
                                <div className="col-md-12 ">
                                  <h3 className="text-center appheading">
                                    Trouble logging in?
                                  </h3>
                                  <p className="text-center mt-2">
                                    Enter your email and we'll send <br />
                                    you a OTP to get back into your account.
                                  </p>
                                </div>
                                <div className="col-md-2"></div>
                                <div className="col-md-8 mt-2">
                                  <TextField
                                    required
                                    name="email"
                                    fullWidth
                                    placeholder="Enter your email"
                                    id="standard-basic"
                                    variant="outlined"
                                    onChange={(e) =>
                                      setFieldValue("email", e.target.value)
                                    }
                                    error={
                                      touched.email && Boolean(errors.email)
                                    }
                                    helperText={touched.email && errors.email}
                                  />
                                </div>
                                <div className="col-md-6"></div>

                                <div className="itemcenter mt-4">
                                  <LoadingButton
                                      loadingIndicator={<CircularProgress size={20}  style={{
                                        color:"white"
                                      }} />}
                                    loading={loading}
                                    style={{ background: "#0d407d" }}
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    onClick={() => handleSubmit()}
                                  >
                                    Send OTP
                                  </LoadingButton>
                                </div>
                                <div className="itemcenter mt-3">
                                  <LoadingButton
                                    onClick={() => navigate("/signin")}
                                    color="primary"
                                  >
                                    <p>Go back</p>
                                  </LoadingButton>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
