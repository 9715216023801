import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/loader";
import { AuthContext } from "../../helpers/context/auth.context";
import { inventoryCategoriesContext } from "../../helpers/context/inventory_categories.context";
import FullLayout from "../../layouts/full_layout";
const DashBoard = () => {
  const { loggedInUser } = useContext<any>(AuthContext);
  const { loader } = useContext(inventoryCategoriesContext);
  const navigate = useNavigate();
  useEffect(() => {
    loggedInUser.menu?.length <= 0 ||
      (!loggedInUser.menu && navigate("/unknown-error"));
  }, []);
  return <div>{!loggedInUser ? <Loader /> : <FullLayout />}</div>;
};

export default DashBoard;
