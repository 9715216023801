import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/styles/main.css";
import ForgotPassword from "./pages/auth/forgot_password";

import { AuthContext } from "./helpers/context/auth.context";
import ResetPassword from "./pages/auth/reset_password";
import SignIn from "./pages/auth/sign-in";
import DashBoard from "./pages/dashboard";
import PageNotFound from "./pages/page_not_found";
import PrivateRoute from "./pages/private_route";
import UnknownErrorPage from "./pages/unknown_error";
import { navigation } from "./routes/router";
const App = () => {
  const { setLoggedInUser } = useContext(AuthContext);
  //Lazy loading
  const allAreaPaths = (path: any) => {
    const DynamicLazyLoading = React.lazy(() => import(`${path}`));
    return <PrivateRoute Children={DynamicLazyLoading} />;
  };
  useEffect(() => {
    let localStorageValues = localStorage.getItem("user");
    setLoggedInUser(
      localStorageValues ? JSON.parse(localStorageValues) : undefined
    );
  }, []);
  return (
    <>
      {/* Notification Toaster */}
      <ToastContainer position="top-left" />
      {/* All Area Routing */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<PrivateRoute Children={DashBoard} />}>
            {navigation.map((x) => {
              if (!x.children) {
                return (
                  <Route
                    key={x.key}
                    path={x.href}
                    element={<>{allAreaPaths(x.path)}</>}
                  />
                );
              } else {
                return x.children.map((children, index) => {
                  return (
                    <Route
                      key={children.key}
                      path={children.href}
                      element={<>{allAreaPaths(children.path)}</>}
                    />
                  );
                });
              }
            })}
          </Route>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/forgot-your-password" element={<ForgotPassword />} />
          <Route path="/reset-your-password" element={<ResetPassword />} />
          <Route path="/unknown-error" element={<UnknownErrorPage />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
