import MenuIcon from "@mui/icons-material/Menu";
import { Avatar } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/styles/header.css";
import { successNotify } from "../components/notifications";
import { AuthContext } from "../helpers/context/auth.context";
interface INavbarProps {
  drawerWidth: any;
  open: any;
}
const Navbar = (props: INavbarProps) => {
  let { loggedInUser: user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutHandler = () => {
    localStorage.clear();
    navigate("/signin");
    successNotify("Logout Success.");
  };
  return (
    <AppBar
      className="back"
      style={{ background: "#0d407d" }}
      position="fixed"
      sx={{
        width: { md: `calc(100% - ${props.drawerWidth}px)` },
        ml: { md: `${props.drawerWidth}px` },
      }}
    >
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => navigate("/user-profile")}>
          My Profile
        </MenuItem>
        <MenuItem onClick={() => logoutHandler()}>Logout</MenuItem>
      </Menu>
      <div className="main">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => props.open(true)}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            className="cursorstyling"
            onClick={() => navigate("/")}
            variant="h6"
            noWrap
            component="div"
          >
            Cloud Office CRM
          </Typography>
        </Toolbar>
        <div
          onClick={handleClick}
          className="cursorstyling"
          style={{ marginRight: "2rem", marginTop: "0.7rem" }}
        >
          <Avatar src={user?.image} />
        </div>
      </div>
    </AppBar>
  );
};
export default Navbar;
