import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useState } from "react";
import "../assets/styles/footer.css";
import Navbar from "./header";
import Sidebar from "./sidebar";
const FullLayout = () => {
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const [width, setWidth] = useState<number | string>();

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Navbar drawerWidth={width} open={setMobileOpen} />
        <Sidebar
          setMobileOpen={setMobileOpen}
          mobileOpen={mobileOpen}
          width={setWidth}
        />
      </Box>
    </>
  );
};

export default FullLayout;
