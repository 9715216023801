import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/loader/loader";
import { AuthContext } from "../helpers/context/auth.context";
import { navigation } from "../routes/router";
const PrivateRoute = ({ Children }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedInUser } = useContext(AuthContext);
  let login = localStorage.getItem("user");

  useEffect(() => {
    if (
      !login &&
      location.pathname !== "/reset-your-password" &&
      location.pathname !== "/forgot-your-password"
    ) {
      navigate("/signin");
    }
    if (location.pathname !== "/") {
      navigation.filter((x) => {
        if (!x.children) {
          if (x.href === location.pathname && x.menu !== undefined) {
            if (!loggedInUser?.menu?.includes(x.menu?.toString())) {
              navigate("/pagentofound");
            }
          }
        } else {
          x.children.filter((n) => {
            if (n.href === location.pathname && x.menu !== undefined) {
              if (!loggedInUser?.menu?.includes(n.menu?.toString())) {
                navigate("/pagenotfound");
              }
            }
          });
        }
      });
    }
  }, [location.pathname]);
  return <div>{!login ? <Loader /> : <Children />}</div>;
};
export default PrivateRoute;
