import { IAddEmployeeProfile } from "../interfaces/request/IAddEmployeeProfile";
import { IAddInventoryFormFields } from "../interfaces/request/IAddInventoryFormFields";
import { IAddLeaveDeductionCategory } from "../interfaces/request/IAddLeaveDeduction";
import { IAddLeaveTypes } from "../interfaces/request/IAddLeaveTypes";
import { IAddSalaryRules } from "../interfaces/request/IAddSalaryRules";
import { IChangeLeaveStatus } from "../interfaces/request/IChangeLeaveStatus";
import { ICreateFormResponse } from "../interfaces/request/ICreateFormResponse";
import { ICreateInventoryFields } from "../interfaces/request/ICreateInventoryFields";
import { IDeleteInevntorySpecificField } from "../interfaces/request/IDeleteInventorySpecificField";
import { ILoginRequest } from "../interfaces/request/ILoginRequest";
import { IResetPassword } from "../interfaces/request/IResetPassword";
import { ISubmitInventoryResponse } from "../interfaces/request/ISubmitInventoryForm";
import { IUpdateAdmin } from "../interfaces/request/IUpdateAdmin";
import { IUpdateDepartments } from "../interfaces/request/IUpdateDepartments";
import { IUpdateEmployeeProfile } from "../interfaces/request/IUpdateEmployeeProfile";
import { IUpdateLeaveTypes } from "../interfaces/request/IUpdateLeaveTypes";
import { IUpdateProfilePassword } from "../interfaces/request/IUpdateProfilePassword";
import { IUpdateRoles } from "../interfaces/request/IUpdateRoles";
import { IUpdateSpeicficForm } from "../interfaces/request/IUpdateSpeicficForm";
import { IAddOrganizationsResponse } from "../interfaces/response/IAddOrganizationsResponse";
import { IAddRolesResponse } from "../interfaces/response/IAddRolesResponse";
import { IAddSalaryRulesResponse } from "../interfaces/response/IAddSalaryRulesResponse";
import { IAllDepartmentsListResponse } from "../interfaces/response/IAllDepartmentsListResponse";
import { IAllLeaveReasonListResponse } from "../interfaces/response/IAllLeaveReasonsListResponse";
import { IAllManagersListResponse } from "../interfaces/response/IAllMangersListResponse";
import { IAllOrganizationDetails } from "../interfaces/response/IAllOrganizatiosDetail";
import { IAppliedLeavesResponse } from "../interfaces/response/IAppliedLeavesResponse";
import { IBaseResponse } from "../interfaces/response/IBaseResponse";
import { IEmployeeProfileListResponse } from "../interfaces/response/IEmployeeProfilesListResponse";
import { IEmployeeRolesResponse } from "../interfaces/response/IEmployeeRolesResponse";
import { IAllLeaveTypesResponse } from "../interfaces/response/IGetAllLeaveTypesListResponse";
import { IEmployeesResponse } from "../interfaces/response/IGetEmployeesResponse";
import { IInventoryAllFormsListResponse } from "../interfaces/response/IInventoryAllFormsListResponse";
import {
  IInventoryFormFieldsData,
  IInventoryFormFieldsResponse,
} from "../interfaces/response/IInventoryFormFieldsResponse";
import { ILeaveAssignableResponse } from "../interfaces/response/ILeaveAssignableResponse";
import { ILeaveDeductionCategory } from "../interfaces/response/ILeaveDeductionCategoryRespone";
import { ILeavesListResponse } from "../interfaces/response/ILeavesListResponse";
import { ILeaveTypesResponse } from "../interfaces/response/ILeaveTypesListResponse";
import { IMenusListResponse } from "../interfaces/response/IMenusListResponse";
import { IPartcularLeaveResponse } from "../interfaces/response/IParticularLeaveDetailResponse";
import { IParticularOrganizationResponse } from "../interfaces/response/IParticularOrganizationResponse";
import { ISalaryRulesResponse } from "../interfaces/response/ISalaryRulesResponse";
import { ISearchCategoryRecordsResponse } from "../interfaces/response/ISearchCategoryRecordsResponse";
import { ISpecificUserDetailsResponse } from "../interfaces/response/ISpecificEmployeeDetails";
import { ISpecificFormListResponse } from "../interfaces/response/ISpecificFormListResponse";
import { ISpecificLeaveDetailsResponse } from "../interfaces/response/ISpecificLeaveDetailsResponse";
import { IUploadInventoryFilesResponse } from "../interfaces/response/iUploadInventoryFilesResponse";
import { default as getAllApiBase } from "../utils/all_api_base";

class CompanyManagementServices {
  //auth

  login = (values: ILoginRequest): Promise<IBaseResponse> => {
    return getAllApiBase
      .post("/api/v1/employee/login", values)
      .then((res) => res.data)
      .catch((err) => err);
  };
  forgotPassword = (data: { email: string }) => {
    return getAllApiBase
      .post("/api/v1/employee/forgot_password", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  resetPassword = (data: IResetPassword) => {
    return getAllApiBase
      .post("/api/v1/employee/reset_forgot_password", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateProfilePassword = async (data: IUpdateProfilePassword) => {
    return getAllApiBase
      .put("/api/v1/employee/reset_password", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  //organizations
  addOrganization = (data: any): Promise<IAddOrganizationsResponse> => {
    return getAllApiBase
      .post("/api/v1/organization/add", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateOrganization = (data: FormData) => {
    return getAllApiBase
      .put("/api/v1/organization/update", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getOrganizationDetails = (): Promise<IAllOrganizationDetails> => {
    return getAllApiBase
      .get("/api/v1/organization")
      .then((res) => res.data)
      .catch((err) => err);
  };
  getParticularorganizationDetail = (
    id: string
  ): Promise<IParticularOrganizationResponse> => {
    return getAllApiBase
      .get(`/api/v1/organization/details/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteOrganization = (id: string) => {
    return getAllApiBase
      .delete(`/api/v1/organization/delete/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };

  addEmployees = (data: any) => {
    return getAllApiBase
      .post("/api/v1/employee/add", data)
      .then((res) => res.data)
      .catch((err) => err);
  };

  createInventoryForm = (
    data: ICreateInventoryFields[],
    formname: string
  ): Promise<ICreateFormResponse> => {
    return getAllApiBase
      .post("/api/v1/inventory/add_form", {
        key: formname,
        fields: data,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  checkFormKeyExistence = (key: string) => {
    return getAllApiBase
      .get(`/inventory/key_existance/${key}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getAllInventoryFormsLists = (): Promise<IInventoryAllFormsListResponse> => {
    return getAllApiBase
      .get("/api/v1/inventory/form_list")
      .then((res) => res.data)
      .catch((err) => err);
  };
  getInventoryFieldsList = (
    name: string
  ): Promise<IInventoryFormFieldsResponse> => {
    return getAllApiBase
      .get(`/api/v1/inventory/form_fields/${name}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  submitInventoryForm = (
    data: ISubmitInventoryResponse
  ): Promise<IBaseResponse> => {
    console.log(data, "skdkdk");
    return getAllApiBase
      .post("/api/v1/inventory/add", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteInventoryForm = (id: string) => {
    return getAllApiBase
      .delete(`/api/v1/inventory/delete_form/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getSubmitInventoryFormAnswersDetail = (key: string) => {
    return getAllApiBase
      .get(`/api/v1/inventory/details/${key}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateInventoryForm = (data: IInventoryFormFieldsData) => {
    return getAllApiBase
      .put("/api/v1/inventory/update_form", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteInventoryFields = (data: IDeleteInevntorySpecificField) => {
    return getAllApiBase
      .delete("/api/v1/inventory/form_field", {
        data,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  addInventoryFormFields = (data: IAddInventoryFormFields) => {
    return getAllApiBase
      .post("/api/v1/inventory/form_field", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteSpecificFormFieldRecord = (data: { inventory_id: string }) => {
    return getAllApiBase
      .delete("/api/v1/inventory", {
        data,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  getSpecificFormsList = (key: string): Promise<ISpecificFormListResponse> => {
    return getAllApiBase
      .get(`/api/v1/inventory/form_fields/${key}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getParticularRecordDetails = (inventory_id: string) => {
    return getAllApiBase
      .get(`/api/v1/inventory/${inventory_id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateFormRecord = (data: any) => {
    return getAllApiBase
      .put("/api/v1/inventory", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  searchInventoryCategory = (
    search: string
  ): Promise<ISearchCategoryRecordsResponse> => {
    return getAllApiBase
      .get(`/api/v1/inventory/form?search=${search}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  //Employees

  getAllEmployeesData = (): Promise<IEmployeesResponse> => {
    return getAllApiBase
      .get("/api/v1/employee/allEmployeeDetails")
      .then((res) => res.data)
      .catch((err) => err);
  };
  getAllLeavetypesList = (): Promise<IAllLeaveTypesResponse> => {
    return getAllApiBase
      .get("/api/v1/leave/type/list")
      .then((res) => res.data)
      .catch((err) => err);
  };
  addLeaveTypes = (data: IAddLeaveTypes) => {
    return getAllApiBase
      .post("/api/v1/leave/type", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateLeaveTypes = (data: IUpdateLeaveTypes) => {
    return getAllApiBase
      .put("/api/v1/leave/type", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteLeaveTypes = (id: string) => {
    return getAllApiBase
      .delete(`/api/v1/leave/type/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getAllLeaveReasonsList = (): Promise<IAllLeaveReasonListResponse> => {
    return getAllApiBase
      .get("/api/v1/leave/reason/list")
      .then((res) => res.data)
      .catch((err) => err);
  };
  addLeaveReason = (leave_reason: string) => {
    return getAllApiBase
      .post("/api/v1/leave/reason", {
        leave_reason,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateLeaveReason = (data: {
    leave_reason: string;
    leave_reason_id: string;
  }) => {
    return getAllApiBase
      .put("/api/v1/leave/reason", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteLeaveReason = (id: string) => {
    return getAllApiBase
      .delete(`/api/v1/leave/reason/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getAllEmployeeProfilesList = (): Promise<IEmployeeProfileListResponse> => {
    return getAllApiBase
      .get("/api/v1/profile")
      .then((res) => res.data)
      .catch((err) => err);
  };
  getDesignation = (id: string) => {
    return getAllApiBase
      .get(`/api/v1/profile/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  addEmployeeProfile = (data: IAddEmployeeProfile) => {
    return getAllApiBase
      .post("/api/v1/profile/add", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getAllDepartmentsList = (): Promise<IAllDepartmentsListResponse> => {
    return getAllApiBase
      .get("/api/v1/department")
      .then((res) => res.data)
      .catch((err) => err);
  };
  addDepartments = (name: string) => {
    return getAllApiBase
      .post("/api/v1/department", { name })
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateDepartMent = (data: IUpdateDepartments) => {
    return getAllApiBase
      .put("/api/v1/department", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteDepartMent = (id: string) => {
    return getAllApiBase
      .delete(`/api/v1/department/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getEmployeesRolesList = (): Promise<IEmployeeRolesResponse> => {
    return getAllApiBase
      .get("/api/v1/employee/role")
      .then((res) => res.data)
      .catch((err) => err);
  };

  getAllManagersList = (): Promise<IAllManagersListResponse> => {
    return getAllApiBase
      .get("/api/v1/employee/allManagerList")
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateEmployeeProfile = (data: IUpdateEmployeeProfile) => {
    return getAllApiBase
      .put("/api/v1/profile/update", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteEmployeeProfile = (id: string) => {
    return getAllApiBase
      .delete(`/api/v1/profile/delete/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateEmployee = (data: any) => {
    return getAllApiBase
      .put("/api/v1/employee/update", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getSpecificEmployeeDetails = (
    id: string
  ): Promise<ISpecificUserDetailsResponse> => {
    return getAllApiBase
      .get(`/api/v1/employee/details/${id}`)
      .then((res) => res.data)
      .catch((err) => err.rsponse.data);
  };
  deleteEmployee = (id: string) => {
    return getAllApiBase
      .delete(`/api/v1/employee/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateAdmin = (data: IUpdateAdmin) => {
    return getAllApiBase
      .put(`/api/v1/employee/update_admin`, data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getAdminDetails = (id: string) => {
    return getAllApiBase
      .get(`/api/v1/employee/details_org_base/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };

  //employee
  leaveTypeList = (): Promise<ILeaveTypesResponse> => {
    return getAllApiBase
      .get("/api/v1/leave/type/list")
      .then((res) => res.data)
      .catch((err) => err);
  };
  addLeave = (data: FormData) => {
    return getAllApiBase
      .post("/api/v1/leave/add", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getLeavesList = (): Promise<ILeavesListResponse> => {
    return getAllApiBase
      .get("/api/v1/leave")
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteParticularLeave = (id: string) => {
    return getAllApiBase
      .delete(`/api/v1/leave/delete/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getParticularLeaveDetails = (
    id: string
  ): Promise<IPartcularLeaveResponse> => {
    return getAllApiBase
      .get(`/api/v1/leave/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };

  getAllEmployeesAppliedLeavesList = () => {
    return getAllApiBase
      .get("/api/v1/leave/history_all_employee")
      .then((res) => res.data)
      .catch((err) => err);
  };

  //add dynamicRoles
  addDynamicRoles = (data: {
    name: string;
    app_menus: any;
    leave_approve_roles: string[];
  }) => {
    return getAllApiBase
      .post("/api/v1/role", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getDynamicRolesList = (): Promise<IAddRolesResponse> => {
    return getAllApiBase
      .get("/api/v1/role")
      .then((res) => res.data)
      .catch((err) => err);
  };
  getDynamicLeaveApprovalRolesList = (
    id: string
  ): Promise<IAddRolesResponse> => {
    return getAllApiBase
      .post("api/v1/role/leave_approval_roles_available", {
        role_id: id,
      })
      .then((res) => res.data)
      .catch((err) => err);
  };

  getAllLeaveApprovalRolesList = (): Promise<IAddRolesResponse> => {
    return getAllApiBase
      .post("api/v1/role/leave_approval_roles_available")
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateRoles = (data: IUpdateRoles) => {
    return getAllApiBase
      .put("/api/v1/role", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteRoles = (role_id: string) => {
    return getAllApiBase
      .delete(`/api/v1/role/${role_id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  assignAvailableRoles = (): Promise<ILeaveAssignableResponse> => {
    return getAllApiBase
      .get("/api/v1/role/leave_approval_roles_available")
      .then((res) => res.data)
      .catch((err) => err);
  };
  getMenusList = (): Promise<IMenusListResponse> => {
    return getAllApiBase
      .get("/api/v1/app_menus")
      .then((res) => res.data)
      .catch((err) => err);
  };
  getAllAppliedLeavesList = (data: {
    month: number;
    year: number;
  }): Promise<IAppliedLeavesResponse | any> => {
    return getAllApiBase
      .post("/api/v1/leave/all_leave", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getSpecificLeaveDetails = (
    id: string
  ): Promise<ISpecificLeaveDetailsResponse> => {
    return getAllApiBase
      .get(`/api/v1/leave/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  changeLeaveStatus = (data: IChangeLeaveStatus) => {
    return getAllApiBase
      .put("/api/v1/leave/change_status", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getSalaryRules = (): Promise<ISalaryRulesResponse> => {
    return getAllApiBase
      .get("/api/v1/employee/salary/rules")
      .then((res) => res.data)
      .catch((err) => err);
  };
  addSalaryRules = (
    data: IAddSalaryRules
  ): Promise<IAddSalaryRulesResponse> => {
    return getAllApiBase
      .post("/api/v1/employee/salary/rules", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteSalaryRules = (id: string) => {
    return getAllApiBase
      .delete(`/api/v1/employee/salary/rules/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateSalaryRules = (data: IAddSalaryRules) => {
    return getAllApiBase
      .put("/api/v1/employee/salary/rules", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  getLeaveDeductionCategory = (): Promise<ILeaveDeductionCategory> => {
    return getAllApiBase
      .get("/api/v1/leave/deduction_category")
      .then((res) => res.data)
      .catch((err) => err);
  };

  addLeaveDeductionCategory = (data: IAddLeaveDeductionCategory) => {
    return getAllApiBase
      .post("/api/v1/leave/deduction_category", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateLeaveDeductionCategory = (data: IAddLeaveDeductionCategory) => {
    return getAllApiBase
      .put("/api/v1/leave/deduction_category", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteLeaveDeductionCategory = (id: string) => {
    return getAllApiBase
      .delete(`/api/v1/leave/deduction_category/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };
  updateSpecificinventoryForm = (data: IUpdateSpeicficForm) => {
    return getAllApiBase
      .put("/api/v1/inventory/update_form", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  deleteSpecificInventoryForm = (id: string) => {
    return getAllApiBase
      .delete(`/api/v1/inventory/delete_form/${id}`)
      .then((res) => res.data)
      .catch((err) => err);
  };

  uploadInventoryFiles = (
    data: FormData
  ): Promise<IUploadInventoryFilesResponse> => {
    return getAllApiBase
      .post("/api/v1/upload_file", data)
      .then((res) => res.data)
      .catch((err) => err);
  };
  downloadFile = (filepath: string) => {
    return getAllApiBase
      .post("/api/v1/signed_url", { filepath })
      .then((res) => res.data)
      .catch((err) => err);
  };
}

export default new CompanyManagementServices();
