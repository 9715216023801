import axios, { AxiosError } from "axios";
import StatusCodes from "http-status-codes";
export const base_url = "https://api.cloudofficecrm.com/";
// export const base_url = "http://localhost:8001/";

const axiosInstance = axios.create({
  baseURL: base_url,
});
const requestHandler = (request: any) => {
  const currentUser: any = JSON.parse(localStorage.getItem("user") as any);
  try {
    if (
      currentUser &&
      currentUser?.token !== undefined &&
      currentUser?.token !== ""
    )
      request.headers["Authorization"] = "Bearer " + currentUser?.token;
  } catch (e) {
    console.log(e, "err");
  }
  return request;
};
const responseHandler = (response: any) => {
  return response;
};
axiosInstance.interceptors.request.use(requestHandler, (error: AxiosError) => {
  Promise.reject(error);
});
axiosInstance.interceptors.response.use(
  responseHandler,
  (error: AxiosError) => {
    if (error.response && error.response.status !== StatusCodes.UNAUTHORIZED) {
      if (error.response.data) return Promise.reject(error.response.data);
      return Promise.reject(error);
    }
    if (error.response && error.response.status === StatusCodes.UNAUTHORIZED) {
      localStorage.removeItem("user");
      window.location.href = "/signin";
      if (error.response.data) return Promise.reject(error.response.data);
      return Promise.reject(error);
    } else if (
      error.response &&
      error.response.status === StatusCodes.INTERNAL_SERVER_ERROR
    ) {
      window.location.href = "/pagenotfound";
    }
  }
);

export default axiosInstance;
